import {
    ref,
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useAdvancement() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const levels = ref({});
    const next_year = ref(null);


    const getLevels = async () => {
        try {
            let response = await axios.get('/api/levels');
            levels.value = response.data.data.levels;
            next_year.value = response.data.data.next_year;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }


    const advanceStudnts = async (level_id) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/students/advancement/' + level_id);
            msgs.value = response.data.message
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    return {
        msgs,
        errors,
        levels,
        next_year,
        getLevels,
        advanceStudnts
    }
}