<template>
  <div>
    <feedback-messages :msgs="msgs" :errors="errors" />

    <div class="row">
      <div v-can="'access_students'" class="col-xl-3 col-xxl-3 col-sm-6">
        <router-link :to="{ name: 'Student-Affairs-Students' }">
          <div class="widget-stat card bg-white">
            <div class="card-body red-border-right">
              <div class="media">
                <span class="mr-3">
                  <i class="la la-gear"></i>
                </span>
                <div class="media-body text-white">
                  <h3 class="text-blue">Students</h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <div v-can="'manage_advancement'" class="col-xl-3 col-xxl-3 col-sm-6">
        <a
          href="javascript:void()"
          data-toggle="modal"
          data-target="#manageAdvancement"
        >
          <div class="widget-stat card bg-white">
            <div class="card-body red-border-right">
              <div class="media">
                <span class="mr-3">
                  <i class="la la-gear"></i>
                </span>
                <div class="media-body text-white">
                  <h3 class="text-blue">Advancement</h3>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div v-can="'access_student_transactions'" class="col-xl-3 col-xxl-3 col-sm-6">
        <router-link :to="{ name: 'Student-Affairs-Manage-Student-Credit' }">
          <div class="widget-stat card bg-white">
            <div class="card-body red-border-right">
              <div class="media">
                <span class="mr-3">
                  <i class="la la-gear"></i>
                </span>
                <div class="media-body text-white">
                  <h3 class="text-blue">Manage Student Credit</h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>

    </div>

    <!-- Manage Advancement -->
    <div
      v-can="'lock_payment_profile_students'"
      class="modal fade"
      id="manageAdvancement"
      tabindex="-1"
      role="dialog"
      aria-labelledby="manageAdvancementLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="manageAdvancementLabel">
              Students Advancement
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: red; font-weight: 700; text-align: center"
                >
                  Please note that, the students will be advanced to 
                  "<span v-if="next_year">{{next_year.value}}</span>"
                  Academic Year!
                </label>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Level</label>
                <div class="col-md-9">
                  <select
                    class="form-control"
                    name="level_id"
                    required
                    v-model="level_id"
                  >
                    <option value="null" disabled selected>
                      Nothing Selected
                    </option>
                    <option
                      v-for="level in levels"
                      :key="level.id"
                      :value="level.id"
                    >
                      {{ level.value }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              id="close"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              :disabled="disableBtn"
              class="btn btn-primary"
              @click.prevent="Advance()"
            >
              Advance
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useAdvancement from "@/composables/advancement.js";
import { onBeforeMount, ref, inject } from "vue";
import FeedbackMessages from "@/components/FeedbackMessages.vue";

export default {
  components: {
    FeedbackMessages,
  },
  setup() {
    const hasPermission = inject("hasPermission");
    const disableBtn = ref(false);
    const level_id = ref(null);
    const { levels, getLevels, advanceStudnts, next_year, msgs, errors } =
      useAdvancement();

    onBeforeMount(async () => {
      await getLevels();
    });

    const Advance = () => {
      if (hasPermission("manage_advancement")) {
        disableBtn.value = true;
        advanceStudnts(level_id.value).then(() => {
          window.scrollTo(0, 0);
          disableBtn.value = false;
          document.getElementById("close").click();
        });
      } else {
        alert("You are not permitted!");
      }
    };

    return {
      levels,
      level_id,
      next_year,
      disableBtn,
      errors,
      msgs,
      Advance,
    };
  },
};
</script>
